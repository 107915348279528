import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "stl.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 250, height: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div className="about">
        <h1>Hello!</h1>
        <div>
          <p>
            I'm Vivek Kalyan, currently a machine learning engineer at{" "}
            <a href="https://www.handshakes.com.sg">Handshakes</a>. Previously,
            I graduated from Singapore University of Technology and Design where
            I specialized in Machine Learning.
          </p>
          <Img
            style={{ float: "right" }}
            fixed={data.file.childImageSharp.fixed}
          />
          <p>
            I love making things and exploring new technology. For example, the
            image is a 3D model of me scanned by attaching a Kinect to a KUKA
            industrial robot and writing a script to move the robot arm in an
            arc around my body.
          </p>
          <p>Why? I honestly still dont know.</p>
        </div>
        <p>
          I have worked on a wide array of projects, building a full backend
          content management system for a startup in France, an iOS and Android
          application for SUTD admissions office, a mobile-friendly website
          application for a gamification platform, a black/white to colour image
          transformer, a futuristic space-themed Augmented Reality experience at
          a street in Chinatown, a Visual Question Answering System and many
          many more.
        </p>

        <p>
          When I am not in front of my laptop, I can be found on the field
          playing football.
        </p>
      </div>
    )}
  />
);
